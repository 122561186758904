import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

/* import Image from "../components/image" */
/* import { Link } from "gatsby" */

import styles from "./index.module.css"

const IndexPage = () => (
	<Layout>
	<SEO title="ホーム" />
	<div className={styles.container}>
		<h1>ようこそ</h1>
		<p>
		このページは、フリーランス IT エンジニア 金子正人の個人ウェブサイトです。
		</p>

		<p>
		メニューより各ページをご覧ください。
		</p>
	</div>
	</Layout>
)

export default IndexPage
